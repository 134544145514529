import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { KeyValueStore } from './key-value.store';
import { isAfter } from 'date-fns';

export function keyValueStoreResolver(storeName: string): ResolveFn<KeyValueStore | undefined> {
  return (route: ActivatedRouteSnapshot) => {
    if (route.queryParamMap.has(storeName)) {
      const store = KeyValueStore.fromJSON(route.queryParamMap.get(storeName) ?? '');
      if (isAfter(store.expire, new Date())) {
        return store;
      }
    }
    return undefined;
  };
}
