import { fromUnixTime, getUnixTime } from 'date-fns';

type Primitives = string | number | bigint | boolean | null | undefined | Date;
type StoreType = Record<string, Primitives>;

export class KeyValueStore {
  #store: StoreType = {};
  readonly #expire: Date;

  get expire(): Date {
    return this.#expire;
  }

  private constructor(expire: Date) {
    this.#expire = expire;
  }

  static create(expire: Date) {
    return new KeyValueStore(expire);
  }

  add(key: string, value: Primitives) {
    this.#store[key] = value;
    return this;
  }

  addToMany(values: { [key: string]: Primitives }) {
    this.#store = { ...this.#store, ...values };
    return this;
  }

  remove(key: string) {
    this.#store[key] = undefined;
    return this;
  }

  clear() {
    this.#store = {};
    return this;
  }

  get(key: string): Primitives {
    return this.#store[key];
  }

  getAll(): StoreType {
    return this.#store;
  }

  /**
   * JSON.stringify hasznalja
   */
  private toJSON() {
    return btoa(JSON.stringify({ data: this.#store, expire: getUnixTime(this.#expire) }));
  }

  toString() {
    return this.toJSON();
  }

  /**
   * Amikor vissza akarjuk fejteni string-bol a store-t
   * @param json
   */
  static fromJSON(json: string) {
    const { data, expire } = JSON.parse(atob(json));

    return KeyValueStore.create(fromUnixTime(expire)).addToMany(data);
  }
}
